<template>
  <LayoutDefault>
    <Loading :active="loading" />
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="{ active: page == 'kpi' }" @click="page = 'kpi'">Resultados aprendizaje</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="{ active: page == 'users' }" @click="page = 'users'">Usuarios</a>
        </li>
      </ul>
      <!-- Content Header (Page header) -->
      <div class="content-header mt-2">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
              <h1 class="m-0 text-dark ttU">indicadores</h1>
            </div>
            <!-- /.col -->
            <div class="col-xs-12 col-sm-6"></div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <kpiCoach v-if="page == 'kpi'" />
      <section class="content"  v-if="page == 'users'" >
        
        <div class="container-fluid">
          
          <div class="row">
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Total Dealers registrados</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="totalDealears" class="googleChart__item">
                      <div class="number">
                        <h3>
                          {{ usersTdata[0]?.value ? usersTdata[0]?.value : 0 }}
                        </h3>
                      </div>
                    </div>
                  </div>

                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Dealers Primax</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="dealerPrimax" class="googleChart__item">
                      <div class="number">
                        <h3>
                          {{
                            usersTypeDate[0]?.users_count
                              ? usersTypeDate[0]?.users_count
                              : 0
                          }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Delears Esso</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="dealerEsso" class="googleChart__item">
                      <div class="number blue">
                        <h3>
                          {{
                            usersTypeDate[1]?.users_count
                              ? usersTypeDate[1]?.users_count
                              : 0
                          }}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Top 5 usuarios más activos</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="mostActives" class="googleChart__item"></div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Top 5 usuarios menos activos</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="lessActives" class="googleChart__item"></div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-4">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Número de tickets</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="tickets" class="googleChart__item"></div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-12">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Visitas únicas (últimos 7 días)</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="lastWeek" class="googleChart__item"></div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
            <div class="col-md-12">
              <!-- CARD -->
              <div class="card card--list">
                <div class="card-header">
                  <h3 class="card-title">Visitas únicas (últimos 6 meses)</h3>
                </div>
                <div class="card-body p-0">
                  <!-- CHART -->
                  <div class="googleChart">
                    <div id="lastMonths" class="googleChart__item"></div>
                  </div>
                  <!-- END CHART -->
                </div>
                <!-- /.card-body -->
                <div class="card-footer text-center">
                  <!-- <a href="javascript:void(0)" class="uppercase">Ver todos</a> -->
                </div>
                <!-- /.card-footer -->
              </div>
              <!-- END CARD -->
            </div>
          </div>
        </div>
        <!-- /.container-fluid -->
      </section>
      <!-- /.content -->
    </div>
  </LayoutDefault>
</template>
<script>
import LayoutDefault from "../layout/LayoutAdmin";
import kpiCoach from "./kpiCoach";
import { GoogleCharts } from "google-charts";
import Loading from "../components/Loading";
import userService from "@/services/userService";
import moment from "moment";
import ticketsService from "@/services/ticketsService";
export default {
  name: `Indicators`,
  data() {
    return {
      loading: false,
      usersTdata: [],
      usersTypeDate: [],
      usersViewPage: [],
      lastMonthViews: [],
      lastWeekViews: [],
      tickets: [],
      page : 'kpi'
    };
  },
  components: {
    LayoutDefault,
    Loading,
    kpiCoach
  },
  async mounted() {
    this.loading = false;
    GoogleCharts.load("current", {
      packages: ["corechart", "line", "bar"],
    });
    await this.loadData();
    GoogleCharts.load(this.drawBasic);
  },
  methods: {
    async loadData() {
      let startDateY = moment().subtract(6, "months");
      const endDateY = moment();
      let res = await userService.getUsersData("", "");
      if (res && res.response) {
        this.usersTdata = res.response;
        if (res.response.data) {
          this.usersTdata = res.response.data;
        }
      }
      res = await userService.getUsersData(
        startDateY.toJSON(),
        endDateY.toJSON(),
        false
      );
      if (res && res.response) {
        this.usersTypeDate = res.response;
        if (res.response.data) {
          this.usersTypeDate = res.response.data;
        }
      }
      res = await userService.getPageViews(
        startDateY.toJSON(),
        endDateY.toJSON(),
        "users"
      );
      if (res && res.response) {
        this.usersViewPage = res.response;
        if (res.response.data) {
          this.usersViewPage = res.response.data;
        }
      }
      res = await userService.getPageViews(
        startDateY.toJSON(),
        endDateY.toJSON(),
        "all"
      );
      if (res && res.response) {
        this.lastMonthViews = res.response;
        if (res.response.data) {
          this.lastMonthViews = res.response.data;
        }
      }
      startDateY = moment().subtract(7, "days");
      res = await userService.getPageViews(
        startDateY.toJSON(),
        endDateY.toJSON(),
        "all"
      );
      if (res && res.response) {
        this.lastWeekViews = res.response;
        if (res.response.data) {
          this.lastWeekViews = res.response.data;
        }
      }
      res = await ticketsService.getAllTickets();
      if (res && res.response) {
        this.tickets = res.response;
        if (res.response.data) {
          this.tickets = res.response.data;
        }
      }
    },
    drawBasic() {
      let data = [],
        options;
      // TOTAL DEALERS
      // if (this.usersTdata.length > 0) {
      //   data = this.usersTdata.map((data) => [new Date(data.date), data.value]);
      // }

      // options = {
      //   hAxis: {
      //     title: "Meses",
      //   },
      //   vAxis: {
      //     title: "Dealers Registrados",
      //   },
      //   series: {
      //     0: {
      //       color: "#1E22AA",
      //     },
      //   },
      // };

      // this.drawChart(
      //   document.getElementById("totalDealears"),
      //   [
      //     ["date", "Time of Day"],
      //     ["number", "Dealers"],
      //   ],
      //   data,
      //   "normal",
      //   false,
      //   options,
      //   "LineChart"
      // );

      // DEALERS PRIMAX
      // if (this.usersTypeDate.length > 0) {
      //   data = this.getData(
      //     this.usersTypeDate.find((el) => el.name === "primax").users
      //   );
      // }

      // options = {
      //   hAxis: {
      //     title: "Meses",
      //   },
      //   vAxis: {
      //     title: "Dealers Registrados Primax",
      //   },
      //   series: {
      //     0: {
      //       color: "#1E22AA",
      //     },
      //   },
      // };

      // this.drawChart(
      //   document.getElementById("dealerPrimax"),
      //   [
      //     ["date", "Time of Day"],
      //     ["number", "Dealers"],
      //   ],
      //   data,
      //   "normal",
      //   false,
      //   options,
      //   "LineChart"
      // );

      // DEALERS ESSO
      // if (this.usersTypeDate.length > 0) {
      //   data = this.getData(
      //     this.usersTypeDate.find((el) => el.name === "esso").users
      //   );
      // }

      // options = {
      //   hAxis: {
      //     title: "Meses",
      //   },
      //   vAxis: {
      //     title: "Dealers Registrados Esso",
      //   },
      //   series: {
      //     0: {
      //       color: "#1E22AA",
      //     },
      //   },
      // };

      // this.drawChart(
      //   document.getElementById("dealerEsso"),
      //   [
      //     ["date", "Time of Day"],
      //     ["number", "Dealers"],
      //   ],
      //   data,
      //   "normal",
      //   false,
      //   options,
      //   "LineChart"
      // );
      // MOST ACTIVE USERS
      if (this.usersViewPage.length > 0) {
        let usersPage = this.usersViewPage.sort((a, b) => b.views - a.views);
        usersPage = usersPage.slice(0, 5);
        data = [["Dealer", "Actividad"]];
        data = [
          ...data,
          ...usersPage.map((pageView) => [
            `${pageView?.user?.name} ${pageView?.user?.lastName}`,
            pageView.views,
          ]),
        ];
      }

      options = {
        chartArea: {
          width: "50%",
        },
        hAxis: {
          // title: 'Total Population',
          minValue: 0,
        },
        vAxis: {
          // title: 'City'
        },
      };
      this.drawChart(
        document.getElementById("mostActives"),
        [],
        data,
        "array",
        false,
        options,
        "BarChart"
      );
      // LESS ACTIVE USERS
      if (this.usersViewPage.length > 0) {
        let usersPage = this.usersViewPage.sort((a, b) => a.views - b.views);
        usersPage = usersPage.slice(0, 5);
        data = [["Dealer", "Actividad"]];
        data = [
          ...data,
          ...usersPage.map((pageView) => [
            `${pageView?.user?.name} ${pageView?.user?.lastName}`,
            pageView.views,
          ]),
        ];
      }

      options = {
        chartArea: {
          width: "50%",
        },
        hAxis: {
          // title: 'Total Population',
          minValue: 0,
        },
        vAxis: {
          // title: 'City'
        },
      };

      this.drawChart(
        document.getElementById("lessActives"),
        [],
        data,
        "array",
        false,
        options,
        "BarChart"
      );
      // TICKETS
      options = {
        // title: "Density of Precious Metals, in g/cm^3",
        // width: 600,
        // height: 400,
        bar: {
          groupWidth: "95%",
        },
        legend: {
          position: "none",
        },
      };
      data = [["Element", "Density", { role: "style" }]];
      if (this.tickets.length > 0) {
        data.push(["Total", this.tickets.length, "#FF6900"]);
        data.push([
          "Abiertos",
          this.tickets.filter((el) => el.status.toLowerCase() === "abierto")
            .length,
          "#1E22AA",
        ]);
        data.push([
          "En Proceso",
          this.tickets.filter(
            (el) => el.status.toLowerCase().replace(" ", "") === "enproceso"
          ).length,
          "#1E88AA",
        ]);
        data.push([
          "Cerrados",
          this.tickets.filter((el) => el.status.toLowerCase() === "cerrado")
            .length,
          "#666",
        ]);
      } else {
        data.push(["Total", 0, "#FF6900"]);
        data.push(["Abiertos", 0, "#1E22AA"]);
        data.push(["En Proceso", 0, "#1E88AA"]);
        data.push(["Cerrados", 0, "#666"]);
      }

      this.drawChart(
        document.getElementById("tickets"),
        [],
        data,
        "array",
        true,
        options,
        "ColumnChart"
      );

      // UNIQUE VISITS (LAST MONTH)
      if (this.lastMonthViews.length > 0) {
        data = [
          ["Meses", "Vistas"],
          ...this.getDataGroupMonth(this.lastMonthViews).sort((a, b) => a[0] - b[0]),
        ];
      }
      options = {
        hAxis: {
          format: "d/M/yy",
          gridlines: { count: 15 },
          title: "Meses",
        },
        vAxis: {
          gridlines: { color: "none" },
          minValue: 0,
          title: "Visitas",
        },
      };

      this.drawChart(
        document.getElementById("lastMonths"),
        [
          ["date", "Time of Day"],
          ["number", "Visitas"],
        ],
        data,
        "array",
        false,
        options,
        "LineChart"
      );
      // UNIQUE VISITS (LAST WEEK)
      if (this.lastWeekViews.length > 0) {
        data = [
          ["Dias", "Vistas"],
          ...this.getDataGroup(this.lastWeekViews).sort((a, b) => a[0] - b[0]),
        ];
      }
      console.log(this.getDataGroup(this.lastWeekViews));
      options = {
        hAxis: {
          format: "d/M/yy",
          gridlines: { count: 15 },
          title: "Dias",
        },
        vAxis: {
          gridlines: { color: "none" },
          minValue: 0,
          title: "Visitas",
        },
      };

      this.drawChart(
        document.getElementById("lastWeek"),
        [
          ["date", "Time of Day"],
          ["number", "Visitas"],
        ],
        data,
        "array",
        false,
        options,
        "LineChart"
      );
      setTimeout(() => {
        this.loading = false;
      });
    },
    drawChart(
      el,
      columns = [],
      data,
      typeData,
      view = null,
      options,
      chartType = "LineChart"
    ) {
      try {
        let dataChart, viewChart, chart;
        if (typeData === "normal") {
          dataChart = new GoogleCharts.api.visualization.DataTable();
          columns.forEach((el) => dataChart.addColumn(el[0], el[1]));
          dataChart.addRows(data);
        } else {
          dataChart = new GoogleCharts.api.visualization.arrayToDataTable(data);
        }
        if (view) {
          viewChart = new GoogleCharts.api.visualization.DataView(dataChart);
          viewChart.setColumns([
            0,
            1,
            {
              calc: "stringify",
              sourceColumn: 1,
              type: "string",
              role: "annotation",
            },
            2,
          ]);
        }
        if (chartType === "ColumnChart") {
          chart = new GoogleCharts.api.visualization.ColumnChart(el);
        } else if (chartType === "BarChart") {
          chart = new GoogleCharts.api.visualization.BarChart(el);
        } else if (chartType === "LineChart") {
          chart = new GoogleCharts.api.visualization.LineChart(el);
        }
        if (view) {
          chart.draw(viewChart, options);
        } else {
          chart.draw(dataChart, options);
        }
      } catch (e) {
        console.log(e);
      }
    },
    getDataGroup(res) {
      const startDateD = moment().locale("es").subtract(7, "days");
      const groups = {};
      for (let i = 1; i <= 7; i++) {
        groups[moment(startDateD).locale("es").add(i, "day").format("DD/MMMM")] = 0;
      }
      console.log(groups);
      const data = [];
      res.forEach((val) => {
        const date = val.date;
        if (moment(date).locale("es").format("DD/MMMM") in groups) {
          groups[moment(date).locale("es").format("DD/MMMM")] += val.views;
        }
      });
      Object.keys(groups).forEach((key) => {
        data.push([key, groups[key]]);
      });
      return data;
    },
    getDataGroupMonth(res) {
      const startDateD = moment().locale("es").subtract(6, "months");
      const groups = {};
      for (let i = 1; i <= 6; i++) {
        groups[moment(startDateD).locale("es").add(i, "month").format("MMMM/YYYY")] = 0;
      }
      console.log(groups);
      const data = [];
      res.forEach((val) => {
        const date = val.date;
        if (moment(date).locale("es").format("MMMM/YYYY") in groups) {
          groups[moment(date).locale("es").format("MMMM/YYYY")] += val.views;
        }
      });
      Object.keys(groups).forEach((key) => {
        data.push([key, groups[key]]);
      });
      return data;
    },
    getData(users) {
      const groups = {};
      const data = [];
      users.forEach((val) => {
        const date = val.created_at.split("T")[0];
        if (date in groups) {
          groups[date].push(val);
        } else {
          groups[date] = new Array(val);
        }
      });
      Object.keys(groups).forEach((key) => {
        data.push([new Date(key), groups[key].length]);
      });
      return data;
    },
  },
};
</script>
<style lang="scss">
.googleChart__item {
  display: flex;
  align-items: center;
  justify-content: center;
  .number {
    color: #f26c21;
    h3 {
      font-size: 50px;
    }
    &.primary {
      color: #f26c21;
    }
    &.blue {
      color: #1e22aa;
    }
  }
}
</style>