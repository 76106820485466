<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { ref } from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: `barChart`,
  props: {
      labels: Array,
      values: Array
  },
  setup (props) {
    console.log('props bar -->>>', props)

    const option = ref({
      xAxis: {
          type: 'category',
          data: props.labels
      },
      yAxis: {
          type: 'value'
      },
      series: [{
          data: props.values,
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
          }
      }]
    });

    return { option };
  }
}
</script>
<style scoped>
.chart {
  height: 400px;
}
</style>
