import config from '@/config/config';
import Axios from 'axios';
export default new class ResultsService {
    private config = config.getConfig();
    getToken() {
        return localStorage.getItem('__token');
    }

    async resumeYear(params: any) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/result-resume`,
            params, {
                headers: {
                    'Authorization': `Bearer ${this.getToken()}`,
                }
            })).data;
        } catch (e) {
            let error = e.response
            if (error.data) {
                error = error.data
            }
            return error;
        }
    }
    async getAllStations() {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/stations`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }

    async getVisits(idStation) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/get-visits-eds/${idStation}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getEmployees(idStation) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/get-sellers/${idStation}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    /* $idVisit */
    async getReportYear(year) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/resume-year-bars/${year}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getReportStation(params) {
        // You should have a server side REST API 
        try {
            return (await Axios.post(`${this.config.apiUrl}/resume-station-bars`,params,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
    async getReportVisit(idVisit) {
        // You should have a server side REST API 
        try {
            return (await Axios.get(`${this.config.apiUrl}/resume-visit/${idVisit}`,
                {
                    headers: {
                        'Authorization': `Bearer ${this.getToken()}`
                    }
                })).data;
        } catch (e) {
            return e.response.data;
        }
    }
};

