<template>
    <section class="content">     
        <div class="container-fluid">
            <!-- <div class="form-group">
                <label for="exampleFormControlSelect1" style="display: block">Agregar EDS</label>
                <select
                  class="eds form-control"
                  multiple="multiple"
                  id="exampleFormControlSelect1"
                >
                  <option
                    v-for="(station, index) in stations"
                    :value="station.pbl"
                    :key="index"
                    :selected="
                      newD.eds && newD.eds.length > 0
                        ? newD.eds?.split(',').find((el) => station.pbl == el)
                          ? true
                          : false
                        : false
                    "
                  >
                    {{ station.service_station }} (PBL #{{ station.pbl }})
                  </option>
                </select>
              </div> -->
              <div class="row">
                  <div class="col-md-3">
                    <div class="form-group">
                        <label for="exampleFormControlSelect1" style="display: block">Año</label>
                        <select
                        class="eds form-control"
                        id="exampleFormControlSelect1"
                        @change="changeFilter($event,'year')"
                        v-model="year"
                        >
                        <option
                                        v-for="(year, index) in listYears"
                                        :value="year"
                                        :key="index"
                                    >
                                        <span>{{ year }}</span>
                        </option>
                        </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group">
                        <label for="exampleFormControlSelect1" style="display: block">Estación   </label>
                        <select
                        class="eds form-control"
                        id="exampleFormControlSelect1"
                        @change="changeFilter($event,'station')"
                         v-model="station"
                        >
                        <option :value="null">-</option>
                        <option
                                        v-for="(station, index) in stations"
                                        :value="station.id"
                                        :key="index"
                                    >
                                        <span>{{ station.service_station }} (#{{ station.pbl }})</span>
                                    </option>
                        </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group" v-if="station && listVisits.length > 0">
                        <label for="exampleFormControlSelect1" style="display: block">Visitas</label>
                        <select
                        class="eds form-control"
                        id="exampleFormControlSelect1"
                        @change="changeFilter($event,'visit')"
                        v-model="visit"
                        >
                        <option :value="null">-</option>
                        <option
                                        v-for="(visit, index) in listVisits"
                                        :value="visit.id"
                                        :key="index"
                                    >
                                        <span>Visita (#{{ index +1}}) {{ $filters.formatDate(visit.date_visit) }} </span>
                                    </option>
                        </select>
                    </div>
                  </div>
                <!--  <div class="col-md-3">
                    <div class="form-group" v-if="station && visit && listVisits.length > 0">
                        <label for="exampleFormControlSelect1" style="display: block">Empleados</label>
                        <select
                        class="eds form-control"
                        id="exampleFormControlSelect1"
                        
                        @change="changeFilter($event,'employee')"
                        >
                        <option :value="null">-</option>
                        <option
                                        v-for="(employee, index) in listEmployees"
                                        :value="employee.id"
                                        :key="index"
                                    >
                                        <span>{{ employee.name }} {{ employee.lastName }}</span>
                                    </option>
                        </select>
                    </div>
                  </div>
                  -->
              </div>
              
            <div class="row mt-5">
            <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">First</th>
                          <th scope="col">Last</th>
                          <th scope="col">Handle</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Mark</td>
                          <td>Otto</td>
                          <td>@mdo</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Jacob</td>
                          <td>Thornton</td>
                          <td>@fat</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Larry</td>
                          <td>the Bird</td>
                          <td>@twitter</td>
                        </tr>
                      </tbody>
                    </table>
                <template v-for="(evaluation, index) in listEvaluations" :key="index">
                    <div class="col-xs-12 col-sm-6 mb-3 mb-md-0">
                        <h6 class="m-0 text-dark ttU">{{evaluation?.label}}</h6>
                    </div>
                     <barChart  :labels="getKeys(evaluation)" :values="getValues(evaluation)" />
                </template>
            </div>
        </div>
    </section>
</template>
<script>
import stationService from "@/services/stationService";
import resultsService from "@/services/resultsService";
import barChart from "@/components/barChart";
export default {
   name: `kpiCoaches`,
   components: {
    barChart,
  },
   data: ()=>({
        
        loading:false,
        stations: [],
        toastType: "",
        toastActive: false,
        toastMessage: "",
        datacollection: null,
        listEvaluations: [],
        listYears : [],
        listVisits : [],
        listEmployees: [],
        year: null,
        station: null,
        visit: null,
        listTable : {headers: [], row: []}
    }),
  async  mounted() {
        this.generateYears();
        const currentYear = new Date().getFullYear();
        this.startYear(currentYear)
        this.getAllStations(); 
    },
   methods: {
       async getAllStations() {
            this.loading = true
            
            const res = await stationService.getAllStations()
           
            if (res && res.response) {
                if (res.response?.data) {
                    this.stations = res.response.data
                    
                    return
                }

                this.stations = res.response
            }
            this.loading = false;
          /* console.log('herreer ',this.stations) */
        },
        getKeys(evaluation){
            let keys = Object.keys(evaluation);
            keys = keys.filter((key)=> key != 'label').filter((key)=> key != 'ref_survey').filter((key)=> key != 'id');
            return keys;
        },
        getValues(evaluation){
            let keys = Object.keys(evaluation);
            keys = keys.filter((key)=> key != 'label').filter((key)=> key != 'ref_survey');
            const values = keys.map((key)=> evaluation[key]);
            return values;
        },
        changeFilter($event, filterBy){
          
          const value = $event.target.value;
          if(!value) return;
          switch(filterBy){
            case 'year':
              this.startYear(value)
            break;
            case 'station':
              this.getVisits(value);
              this.filterbyStation({"year":this.year,"station_id": this.station})
            break;
            case 'visit':
              console.log('jjj')
              this.getEmployees();
              this.getReportVisit(value)
            break;
            case 'employee':
              
            break;
          }
         
          
        },
        startYear(year){
              this.generateYears(year);
              this.filterbyYear(year);
        },
        async filterbyYear(year){
          this.loading = true;
          const res = await resultsService.getReportYear(year);
          
          this.listEvaluations.push(res);
          this.loading = false;
        },
        async filterbyStation(params){
          this.loading = true;
          const res = await resultsService.getReportStation(params);
          this.listEvaluations = res;
          this.loading = false;
        },
        async filterbyVisit(filterBy){
          this.loading = true;
          const res = await resultsService.resumeYear({"visit_id":null, "year":filterBy, "station_id": 7, "employee_id":70})
          this.listEvaluations = res['periods'];
          this.loading = false;
        },
      /*   async filterbyEmployee(filterBy){
          const res = await resultsService.getEmployees(this.station)
          this.listEvaluations = res['periods'];
        }, */
        async getEmployees(){
          this.loading = true;
          const res = await resultsService.getEmployees(this.station)
          this.listEmployees = res;
          this.loading = false;
        },
         generateYears(){
           const min = 2021;
           const max = new Date().getFullYear();
           const response = [];
          for(let i = min; i <= max; i++){
            response.push(i.toString());
          }
          this.listYears = response;
         },
         async getVisits(stationId){
          this.loading = true;
          const res = await resultsService.getVisits(stationId)
          console.log('getting visits ', res);
          this.listVisits = res;
           this.loading = false;
         },
        
         async getReportVisit(visitId){
          const res = await resultsService.getReportVisit(visitId);
          console.log('he=====>>> ',res);
        },
        formatReportVisit(){
          // formatReportVisit(res){
          // const resp = [];
          // res.forEach((item)=>{
          //   const title = item.title;
          // })
        }
   }
}
</script>
