import config from '@/config/config';
import Axios from 'axios';
import { Pagination } from "./pagination";
import { handleHTTPError } from "../lib/http";

export interface GetPaginatedStationsOptions {
  pageSize?: number;
  page?: number;
  search?: string;
  user?: {id: string};
  onlyAssociated?: boolean;
}

export interface CreateStationInput {
  pbl: string;
  serviceStation: string;
  pco?: string;
  address?: string;
  city?: string;
  department?: string;
  phone?: string;
  names?: string;
  emails?: string;
  companyName?: string;
  nit?: string;
  clientsType?: string;
  stationType?: string;
}

export interface UpdateStationInput extends CreateStationInput {
  id: string;
}

export default new class StationService {
  private config = config.getConfig();

  getToken() {
    return localStorage.getItem('__token');
  }

  async create(station: CreateStationInput) {
    const payload = JSON.stringify(station)

    try {
      const response = await Axios.post(`${this.config.apiUrl}/stations`, payload, {
        headers: {
          'authorization': `Bearer ${this.getToken()}`,
          'content-type': 'application/json',
          'accept': 'application/json',
        }
      })

      return response.data;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async update(station: UpdateStationInput) {
    const payload = JSON.stringify(station)
    const url = `${this.config.apiUrl}/user/station/${station.id}`;
    const headers = {
      'authorization': `Bearer ${this.getToken()}`,
      'content-type': 'application/json',
      'accept': 'application/json',
    }

    try {
      const response = await Axios.put(url, payload, { headers })

      return response.data.station;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async remove(stationID: string) {
    const url = `${this.config.apiUrl}/stations/${stationID}`;
    const headers = { 'authorization': `Bearer ${this.getToken()}` }

    try {
      const response = await Axios.delete(url, { headers })

      return response.data.station;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async associateStationTo(station: string, user: string) {
    const url = `${this.config.apiUrl}/stations/associate`;
    const headers = {
      'content-type': 'application/json',
      'authorization': `Bearer ${this.getToken()}`
    }

    try {
      const response = await Axios.post(url, JSON.stringify({
        station,
        user,
      }), { headers })

      return response.data.station;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async removeRelation(station: string, user: string) {
    const url = `${this.config.apiUrl}/stations/remove-relation`;
    const headers = {
      'content-type': 'application/json',
      'authorization': `Bearer ${this.getToken()}`
    }

    try {
      const response = await Axios.post(url, JSON.stringify({
        station,
        user,
      }), { headers })

      return response.data.station;
    } catch (e) {
      handleHTTPError(e)
    }
  }

  async uploadFile(formData: any) {
    // You should have a server side REST API 
    try {
      return (await Axios.post(`${this.config.apiUrl}/stations/masiveload`,
        formData, {
          headers: {
            'Authorization': `Bearer ${this.getToken()}`,
            'Content-Type': 'multipart/form-data'
          }
        })).data;
    } catch (e) {
      if (e.response) {
        let error = e.response
        if (error.data) {
          error = error.data
        }
        return error;
      }

      throw e
    }
  }

  async getAllStations() {
    const response = await Axios.get(`${this.config.apiUrl}/stations`, {
      headers: { 'Authorization': `Bearer ${this.getToken()}` }
    })

    return response.data;
  } 

  async getAllStationsWithoutUsers() {
    return (await Axios.get(`${this.config.apiUrl}/stations/single`,
      {
        headers: {
          'Authorization': `Bearer ${this.getToken()}`
        }
      })).data;
  }

  async getPaginatedStationsWithoutUsers(options: GetPaginatedStationsOptions): Promise<Pagination<Record<string, any>>> {
    const query = new URLSearchParams()

    if (options.page) {
      query.set('page', options.page.toString())
    }

    if (options.pageSize) {
      query.set('pageSize', options.pageSize.toString())
    }

    if (options.search) {
      query.set('search', options.search.toString())
    }

    if (options.user) {
      query.set('user', options.user.id.toString())
    }

    if (options.onlyAssociated) {
      query.set('onlyAssociated', options.onlyAssociated ? '1' : '0')
    }

    return (await Axios.get(`${this.config.apiUrl}/paginate-stations?${query.toString()}`, {
      headers: { 'Authorization': `Bearer ${this.getToken()}` }
    })).data;
  }

  async exportStations() {
    try {
      await Axios({
        url: `${this.config.apiUrl.replace('/api', '')}/export`,
        headers: {
          'Authorization': `Bearer ${this.getToken()}`
        },
        responseType: 'blob'
      });
    } catch (e) {
      return e.response.data;
    }
  }
}
